
import { Component, Vue, Watch } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import { Action, Mutation, Getter } from "vuex-class";
import {
  ICustomer,
  ICustomerCreateRequest,
  ICustomerRequest
} from "@/types/customer";
import { CustomerRequest } from "@/models/customer";
import { IUser, IUserListRequest } from "@/types/user";
import { ISelectItem } from "@/types";
import { IZipaddress } from "@/types/zipaddress";
import { IBranchCreateRequest, IBranchRequest } from "@/types/branch";
import { BranchRequest } from "@/models/branch";
import {
  IMoneyforward,
  IMoneyforwardPartnerCreateRequest
} from "@/types/moneyforward";
import {
  MoneyforwardDepartmentCreateRequest,
  MoneyforwardPartnerCreateRequest
} from "@/models/moneyforward";

@Component({})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  //customer
  @Action("customer/userCreate")
  public createCustomer!: (params: ICustomerCreateRequest) => Promise<boolean>;

  @Action("customer/userIsUniqueName")
  public isUniqueCustomerName!: (params: ICustomerRequest) => Promise<boolean>;

  @Action("customer/userIsUniqueShortName")
  public isUniqueCustomerShortName!: (
    params: ICustomerRequest
  ) => Promise<boolean>;

  @Action("customer/userIsUniqueCustomerMfid")
  public isUniqueCustomerMfid!: (params: ICustomerRequest) => Promise<boolean>;

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/userCreate")
  public createBranch!: (params: {
    branches: IBranchCreateRequest[];
  }) => Promise<boolean>;

  @Action("branch/userIsUniqueName")
  public isUniqueBranchName!: (params: IBranchRequest) => Promise<boolean>;

  @Action("branch/userIsUniqueShortName")
  public isUniqueBranchShortName!: (params: IBranchRequest) => Promise<boolean>;

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //user
  @Action("user/userGetList")
  public getUsers!: (params: IUserListRequest) => Promise<boolean>;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  //zipaddress
  @Action("zipaddress/getZipaddress")
  public getZipaddress!: (zipcode: string) => Promise<boolean>;

  @Getter("zipaddress/single")
  public zipaddress!: IZipaddress;

  @Mutation("zipaddress/clear")
  public clearZipaddress!: () => void;

  //moneyforward
  @Action("moneyforward/getRefreshToken")
  public getRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/getReToken")
  public getReToken!: () => Promise<boolean>;

  @Action("moneyforward/createRefreshToken")
  public createRefreshToken!: () => Promise<boolean>;

  @Action("moneyforward/userCreatePartnerAndDepartment")
  public createPartnerAndDepartment!: (
    params: IMoneyforwardPartnerCreateRequest
  ) => Promise<boolean>;

  @Getter("moneyforward/single")
  public mf_response!: IMoneyforward;

  //Watch
  @Watch("customer_params.users")
  public createBranchUserList() {
    this.branchUserList = this.userList.filter(user => {
      return this.customer_params.users.includes(user.value);
    });

    this.branch_params.forEach(branch => {
      branch.users = branch.users.filter(user => {
        return this.customer_params.users.includes(user);
      });
    });
  }

  //パラメータ定義
  public customer_params: ICustomerRequest = new CustomerRequest();
  public branch_params: IBranchRequest[] = [new BranchRequest()];
  public mf_params: IMoneyforwardPartnerCreateRequest =
    new MoneyforwardPartnerCreateRequest();

  //変数定義
  public close_eom = false;
  public submit_dialog = false;
  public valid = true;
  public lazy = false;
  public customer_name_errors: string[] = [];
  public customer_short_name_errors: string[] = [];
  public customer_mfid_errors: string[] = [];
  public postalcode_errors: string[][] = [[]];
  public branchUserList: ISelectItem[] = [];
  public menu = {
    schedule_date: false
  };

  //ルール設定
  public rules = {
    customer_name: [
      (v: string) => !!v || "顧客名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "顧客名は50文字以下で入力してください"
    ],
    customer_kana: [
      (v: string) => !!v || "ﾖﾐｶﾞﾅは必須です",
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    customer_short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    cutoff_day: [
      (v: string) =>
        !v || /^[0-9]+$/.test(v) || "半角数字のみで入力してください"
    ],
    customer_users: [(v: number[]) => !!v.length || "テクネ担当者は必須です"],
    registration_no: [(v: string) => !!v || "登録番号は必須です"],
    schedule_date: [(v: string) => !!v || "登録予定日は必須です"],
    branch_name: [
      (v: string) => !!v || "支店名は必須です",
      (v: string) =>
        (v && v.length <= 50) || "支店名は50文字以下で入力してください"
    ],
    branch_short_name: [
      (v: string) => !!v || "短縮表示名は必須です",
      (v: string) =>
        (v && v.length <= 20) || "短縮表示名は20文字以下で入力してください"
    ],
    branch_kana: [
      (v: string) => !v || /^[ｦ-ﾟ]+$/.test(v) || "半角ｶﾀｶﾅで入力してください"
    ],
    email: [
      (v: string) =>
        !v ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) ||
        "正しいメールアドレスを入力してください"
    ],
    prefecture: [(v: string) => !!v || "都道府県は必須です"],
    address1: [(v: string) => !!v || "住所は必須です"],
    tel: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ],
    fax: [
      (v: string) =>
        !v ||
        /^[0-9]+$/.test(v) ||
        "半角数字のみで入力してください(ハイフン不要)"
    ]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.clearCustomer();
    this.clearBranch();
    this.clearZipaddress();

    await Promise.all([this.getUsers({ per_page: 0 }), this.getRefreshToken()]);
    await this.getReToken();
    await this.createRefreshToken();

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  // 更新時と作成時で問合せ先が違う
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    // マネーフォワード連携===========================================
    this.mf_params.createFromCustomer(this.customer_params);
    this.branch_params.forEach(branch => {
      const mf_branch = new MoneyforwardDepartmentCreateRequest();
      mf_branch.createFromBranch(branch);
      this.mf_params.departments.push(mf_branch);
    });

    result = await this.createPartnerAndDepartment(this.mf_params);
    //================================================================

    this.customer_params.uuid = this.mf_response.id;
    this.branch_params.forEach((branch, index) => {
      branch.uuid = this.mf_response.departments[index].id;
    });

    if (result) {
      result = await this.createCustomer(this.customer_params);
    }

    if (result) {
      this.branch_params.forEach(branch => {
        branch.customer_id = this.customer.id;
      });
      result = await this.createBranch({ branches: this.branch_params });
    }

    if (result) {
      this.$router.go(-1);
    }
  }

  // 顧客名の二重登録チェック
  public async customer_name_validate() {
    // 登録時
    if (!(await this.isUniqueCustomerName(this.customer_params))) {
      return (this.customer_name_errors = ["既に使用されています"]);
    }

    return (this.customer_name_errors = []);
  }

  // 短縮表示名の二重登録チェック
  public async customer_short_name_validate() {
    // 登録時
    if (!(await this.isUniqueCustomerShortName(this.customer_params))) {
      return (this.customer_short_name_errors = ["既に使用されています"]);
    }

    return (this.customer_short_name_errors = []);
  }

  // 顧客IDの二重登録チェック
  public async customer_mfid_validate() {
    if (!this.customer_params.customer_mfid) {
      return (this.customer_mfid_errors = []);
    }
    // 登録時
    if (!(await this.isUniqueCustomerMfid(this.customer_params))) {
      return (this.customer_mfid_errors = ["既に使用されています"]);
    }

    return (this.customer_mfid_errors = []);
  }

  // 登録ステータスを選んだ場合の挙動
  public registrationStatus() {
    this.customer_params.schedule_date = "";
    this.customer_params.registration_no = "";
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  // 住所自動入力
  public async changePostalCode(index: number, postalcode: string) {
    if (!postalcode) {
      this.postalcode_errors[index].splice(0);
      return;
    }

    const regexp = new RegExp(/^[0-9]+$/);
    const result = postalcode.match(regexp);
    if (!result) {
      this.postalcode_errors[index].splice(
        0,
        1,
        "半角数字のみで入力してください(ハイフン不要)"
      );
      return;
    }

    await this.getZipaddress(postalcode);

    if (this.zipaddress.pref) {
      this.branch_params[index].prefecture = this.zipaddress.pref;
      this.branch_params[index].address1 =
        this.zipaddress.city + this.zipaddress.town;
      this.postalcode_errors[index].splice(0);
    }

    if (!this.zipaddress.pref) {
      this.postalcode_errors[index].splice(0, 1, "郵便番号が間違っています");
    }

    if (this.zipaddress.office) {
      this.branch_params[index].name = this.zipaddress.office;
    }
  }

  //支店追加
  public appendBranch() {
    this.branch_params.push(new BranchRequest());
    this.postalcode_errors.push([]);
  }

  //支店削除
  public deleteBranch(index: number) {
    this.branch_params.splice(index, 1);
    this.postalcode_errors.splice(index, 1);
  }

  //バリデーションチェック
  public updated() {
    this.$nextTick(function () {
      (
        this.$refs.form as Vue & {
          validate: () => boolean;
        }
      ).validate();
    });
  }
}
