var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","sm":"10"}},[_c('v-list',{attrs:{"two-line":"","dense":""}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-text ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("顧客名")]),_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.customer.name + "（" + _vm.customer.kana + "）")+" ")]),_c('v-list-item-subtitle',[_vm._v("短縮名")]),_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.customer.short_name))]),_c('v-list-item-subtitle',[_vm._v("顧客分類")]),_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.customer.division || "ー"))]),_c('v-list-item-subtitle',[_vm._v("適格請求書発行事業者登録番号")]),(
                _vm.customer.registration_status == _vm.$registrationStatus.registered
              )?_c('v-list-item-title',[_vm._v(_vm._s(_vm.customer.registration_no))]):_vm._e(),(
                _vm.customer.registration_status ==
                _vm.$registrationStatus.registration_scheduled
              )?_c('v-list-item-title',[_vm._v(" 登録予定（"+_vm._s(_vm.customer.schedule_date)+"）")]):_vm._e(),(
                _vm.customer.registration_status ==
                _vm.$registrationStatus.no_scheduled
              )?_c('v-list-item-title',[_vm._v("登録予定なし")]):_vm._e()],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-cash-clock ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("締め日")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.customer.cutoff_day ? _vm.customer.cutoff_day == 99 ? "月末" : _vm.customer.cutoff_day + " 日" : "ー"))])],1)],1),_c('v-divider'),_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"indigo"}},[_vm._v(" mdi-account ")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v("テクネ担当者")]),_c('v-list-item-title',{staticClass:"mb-2"},_vm._l((_vm.customer.user),function(user,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(user.sales_staff_name + (_vm.customer.user.length - 1 == index ? "" : " , "))+" ")])}),0)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }